export default class UserModel {
  userId: number;
  email: string;
  first_name: string;
  last_name: string;
  authToken: string;
  authServerToken: string;
  activeTrial: string;

  constructor(init: any = {}) {
    this.userId = init.userId || null;
    this.email = init.email || '';
    this.first_name = init.first_name || '';
    this.last_name = init.last_name || '';
    this.authToken = init.authToken || '';
    this.authServerToken = init.authServerToken || '';
    this.activeTrial = init.activeTrial || '';
  }

  get fullName(): string {
    return `${this.first_name} ${this.last_name}`;
  }
}
