import {AbstractControl, FormControl, FormGroup, ValidatorFn} from '@angular/forms';

export class UtilService {

  public validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  public emailValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const isValid = /^(([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])+)?$/.test(control.value);
      return isValid ? null : {'email': true};
    };
  }
}
