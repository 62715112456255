import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TestService} from '@shared/services/test.service';
import UploadBuildHistoryModel from '@shared/models/upload-build-history.model';

@Component({
  selector: 'app-upload-build-history-modal',
  templateUrl: './upload-build-history-modal.component.html'
})
export class UploadBuildHistoryModalComponent implements OnInit {

  history: UploadBuildHistoryModel[] = [];

  constructor(
    private testSrv: TestService,
    private toastSrv: ToastrService,
  ) {
  }

  ngOnInit(): void {
    this.loadHistory();
  }

  loadHistory() {
    this.testSrv.getUploadBuildHistory().subscribe((data) => {
      this.history = data;
    }, (err) => {
      this.toastSrv.error(err.msg || 'Get upload build history failed');
    });
  }
}
