import StageModel from '@shared/models/stage.model';

export default class TrialModel {
  id: string;
  name: string;
  description: string;
  featureTypes: string[];
  stages: StageModel[];
  iosApp: string;
  androidApp: string;

  constructor(init: any = {}) {
    this.id = init._id || init.id || null;
    this.name = (init.name || '').replace(/^(STR|RTR)_/ig, '');
    this.description = init.description || '';
    this.featureTypes = init.featureTypes || [];
    this.stages = (init.stages || []).map((stage) => new StageModel(stage));
    this.iosApp = init.iosApp;
    this.androidApp = init.androidApp;
  }
}
