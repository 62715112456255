import {formatTableAsText, isEqual} from '@shared/helpers/util';

export default class StepModel {
  id: string | number;
  condition: string;
  action: string;
  data: string[][];
  scenarioId: string;
  order: number;
  label: string;

  constructor(init: any = {}) {
    this.id = init._id || init.id || null;
    this.condition = init.condition || '';
    this.action = init.action || '';
    this.data = init.data || [];
    this.scenarioId = init.scenario || init.scenario || null;
    this.order = null;
    this.label = init.label;
  }

  toFeatureFormat() {
    let str = `\n    ${this.condition} ${this.action}`;
    if (this.data.length) {
      const lines = formatTableAsText(this.data).split(/\r\n|\r|\n/g);
      lines.pop();
      for (const line of lines) {
        str += `\n      ${line}`
      }
    }
    return str;
  }

  isEqual(other: StepModel): boolean {
    return this.id == other.id && this.condition == other.condition && this.action == other.action && isEqual(this.data, other.data);
  }
}
