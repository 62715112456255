import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbActiveModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LayoutModule} from './layout/layout.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ToastrModule} from 'ngx-toastr';
import {NgxSpinnerModule} from 'ngx-spinner';
import {FeatureService} from '@shared/services/feature.service';
import {TrialStore} from '@shared/store/trial.store';
import {UtilService} from '@shared/services/util.service';
import {TestService} from '@shared/services/test.service';
import {AuthService} from '@shared/services/auth.service';
import {XAuthTokenInterceptor} from '@shared/helpers/x-auth-token-interceptor';
import {ErrorInterceptor} from '@shared/helpers/error.interceptor';
import {ExportService} from '@shared/services/export.service';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    LayoutModule,
    HttpClientModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    NgbActiveModal,
    AuthService,
    FeatureService,
    TestService,
    UtilService,
    ExportService,
    TrialStore,
    {provide: HTTP_INTERCEPTORS, useClass: XAuthTokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
  ],
  exports: [],
  bootstrap: [
    AppComponent,
  ]
})
export class AppModule {
}
