import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import TrialModel from '@shared/models/trial.model';
import StageModel from '@shared/models/stage.model';
import FeatureModel from '@shared/models/feature.model';
import ScenarioModel from '@shared/models/scenario.model';
import StepModel from '@shared/models/step.model';
import UserModel from '@shared/models/user.model';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '@shared/services/auth.service';
import {FeatureService} from '@shared/services/feature.service';

@Injectable()
export class TrialStore {
  userObserver: Observable<UserModel>;
  userSubject: BehaviorSubject<UserModel>;

  trialsObserver: Observable<TrialModel[]>;
  trialsSubject: BehaviorSubject<TrialModel[]>;

  activeTrialObserver: Observable<TrialModel>;
  activeTrialSubject: BehaviorSubject<TrialModel>;

  activeStageObserver: Observable<StageModel>;
  activeStageSubject: BehaviorSubject<StageModel>;

  activeFeatureObserver: Observable<FeatureModel>;
  activeFeatureSubject: BehaviorSubject<FeatureModel>;

  editingFeatureObserver: Observable<FeatureModel>;
  editingFeatureSubject: BehaviorSubject<FeatureModel>;

  public get user(): UserModel {
    return this.userSubject.value;
  }
  public set user(value: UserModel) {
    this.userSubject.next(value);
  }

  public get trials(): TrialModel[] {
    return this.trialsSubject.value;
  }
  public set trials(value: TrialModel[]) {
    this.trialsSubject.next(value);
  }

  public get activeTrial(): TrialModel {
    return this.activeTrialSubject.value;
  }
  public set activeTrial(value: TrialModel) {
    this.activeTrialSubject.next(value);
  }

  public get activeStage(): StageModel {
    return this.activeStageSubject.value;
  }
  public set activeStage(value: StageModel) {
    this.activeStageSubject.next(value);
  }

  public get activeFeature(): FeatureModel {
    return this.activeFeatureSubject.value;
  }
  public set activeFeature(value: FeatureModel) {
    this.activeFeatureSubject.next(value);
  }

  public get editingFeature(): FeatureModel {
    return this.editingFeatureSubject.value;
  }
  public set editingFeature(value: FeatureModel) {
    this.editingFeatureSubject.next(value);
  }

  constructor(
    private authService: AuthService,
    private featureSrv: FeatureService,
    protected spinner: NgxSpinnerService,
  ) {
    const userJson = localStorage.getItem('user');
    let user = null;
    if (userJson) {
      user = new UserModel(JSON.parse(userJson));
    }

    this.userSubject = new BehaviorSubject<UserModel>(user);
    this.userObserver = this.userSubject.asObservable();

    this.trialsSubject = new BehaviorSubject<TrialModel[]>([]);
    this.trialsObserver = this.trialsSubject.asObservable();

    this.activeTrialSubject = new BehaviorSubject<TrialModel>(null);
    this.activeTrialObserver = this.activeTrialSubject.asObservable();

    this.activeStageSubject = new BehaviorSubject<StageModel>(null);
    this.activeStageObserver = this.activeStageSubject.asObservable();

    this.activeFeatureSubject = new BehaviorSubject<FeatureModel>(null);
    this.activeFeatureObserver = this.activeFeatureSubject.asObservable();

    this.editingFeatureSubject = new BehaviorSubject<FeatureModel>(null);
    this.editingFeatureObserver = this.editingFeatureSubject.asObservable();
  }

  loadUserInfoFromToken(token: string, hasSpinner = true) {
    return new Promise((resolve) => {
      this.authService.login(token, hasSpinner).subscribe((user: UserModel) => {
        console.log('loginUser', user);
        this.user = user;
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', user.authServerToken);
        localStorage.setItem('x-auth-token', user.authToken);
        resolve(user);
      }, () => {
        console.log('loginUser failed', null);
        this.user = null;
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('x-auth-token');
        resolve(null);
      });
    });
  }

  logout(hasSpinner = true) {
    this.authService.logout(hasSpinner).subscribe(() => {
      this.user = null;
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('x-auth-token');
      location.reload();
    });
  }

  loadTrials() {
    this.featureSrv.getTrials().subscribe((trials) => {
      this.trials = trials;
      const activeTrial = trials.find((trial) => trial.id === this.user.activeTrial);
      this.setActiveTrial(activeTrial || trials[0]);
    }, () => {
      this.trials = [];
      this.setActiveTrial(null);
    });
  }

  findTrialById(trialId: string): TrialModel {
    return this.trials.find((t) => t.id == trialId);
  }

  setActiveTrial(trial: TrialModel) {
    if (trial) {
      this.authService.setActiveTrial(trial.id).subscribe();
      this.user.activeTrial = trial.id;

      this.featureSrv.getStages(trial).subscribe((stages) => {
        trial.stages = stages;
        this.activeTrial = trial;
        this.setActiveStage(stages[0]);
      }, () => {
        trial.stages = [];
        this.activeTrial = trial;
        this.setActiveStage(null);
      });
    } else {
      this.activeTrial = null;
      this.setActiveStage(null);
    }
  }

  findStageById(stageId: string) {
    for (const trial of this.trials) {
      for (const stage of trial.stages) {
        if (stage.id == stageId) {
          return {
            trial,
            stage,
          };
        }
      }
    }
    return null;
  }

  setActiveStage(stage: StageModel, newFeature = null) {
    if (!stage) {
      this.activeStage = null;
      this.setActiveFeature(null);
    } else {
      this.featureSrv.getFeatures(stage).subscribe((features) => {
        stage.features = features;
        this.activeStage = stage;
        if (this.activeStage.isGlobalStage && !stage.features.length) {
          this.createPredefinedGlobalFeatures(stage).subscribe(result => {
            console.log(result);
          }, err => {
            console.log(err);
          });
        } else {
          if (newFeature) {
            newFeature = stage.features.find((f) => f.id == newFeature.id);
          }
          this.setActiveFeature(newFeature || stage.features[0]);
        }
      }, () => {
        stage.features = [];
        this.activeStage = stage;
        this.setActiveStage(null);
      });
    }
  }

  setActiveFeature(feature: FeatureModel) {
    if (!feature) {
      this.activeFeature = null;
    } else {
      this.featureSrv.getScenarios(feature).subscribe((scenarios) => {
        if (scenarios.length > 0) {
          scenarios = scenarios.sort((a, b) => a.order - b.order);
          if (!scenarios[0].order) {
            scenarios.forEach((scenario, index) => {
              scenario.order = index + 1;
              this.featureSrv.editScenario(scenario, false).subscribe();
            });
          }
        }
        feature.scenarios = scenarios;
        this.activeFeature = feature;
      }, () => {
        feature.scenarios = [];
        this.activeFeature = feature;
      });
    }
  }

  findFeatureById(featureId: string | number) {
    if (typeof featureId === 'number')
      return null;

    for (const trial of this.trials) {
      for (const stage of trial.stages) {
        for (const feature of stage.features) {
          if (feature.id == featureId) {
            return {
              trial,
              stage,
              feature,
            };
          }
        }
      }
    }
    return null;
  }

  createFeature(feature: FeatureModel, hasSpinner = true) {
    if (hasSpinner)
      this.spinner.show().then();

    return new Observable(observer => {
      this.featureSrv.createFeature(feature).subscribe(async (newFeature) => {
        const stage = this.activeTrial.stages.find((s) => s.id == newFeature.stageId);
        stage.features.push(newFeature);
        this.activeStage = stage;
        this.activeFeature = newFeature;

        await Promise.all(feature.scenarios.map((scenario) => (
          new Promise((resolve) => {
            scenario.featureId = newFeature.id;
            this.createScenario(scenario, false).subscribe(resolve);
          })
        )));
        this.setActiveFeature(newFeature);

        if (hasSpinner)
          this.spinner.hide().then();
        observer.next('New feature has been created successfully.');
        observer.complete();
      }, (error) => {
        if (hasSpinner)
          this.spinner.hide().then();
        observer.error(error.message || 'Creating new feature has been failed.');
        observer.complete();
      });
    });
  }

  editFeature(feature: FeatureModel, hasSpinner = true) {
    if (hasSpinner)
      this.spinner.show().then();

    const tree = this.findFeatureById(feature.id);

    return new Observable(observer => {
      if (!tree)
        observer.error('Cannot find editing feature.');

      new Promise((resolve, reject) => {
        if (feature.isEqual(tree.feature) && feature.stageId == tree.feature.stageId) {
          resolve(feature);
          return;
        }

        this.featureSrv.editFeature(feature, false).subscribe((newFeature) => {
          newFeature.id = feature.id;
          if (newFeature.stageId == this.activeStage.id) {
            if (tree.stage == this.activeStage) {
              this.activeStage.features = this.activeStage.features.map((f) => f.id == newFeature.id ? newFeature : f);
            } else {
              tree.stage.features = tree.stage.features.filter((f) => f.id != newFeature.id);
              this.activeStage.features.push(newFeature);
            }
          }
          resolve(newFeature);
        }, (error) => {
          reject(error.message || 'Editing feature has been failed.');
        });
      }).then(async (newFeature: FeatureModel) => {
        this.activeFeature = newFeature;
        const updates = [];
        feature.scenarios.forEach((scenario) => {
          const orgScenario = tree.feature.scenarios.find((s) => s.id == scenario.id);
          if (!orgScenario) {
            updates.push(
              new Promise((resolve, reject) => {
                scenario.featureId = feature.id;
                this.createScenario(scenario, false).subscribe(resolve, reject);
              })
            );
          } else {
            updates.push(
              new Promise((resolve, reject) => {
                this.editScenario(scenario, orgScenario, false).subscribe(resolve, reject);
              })
            );
          }
        });
        tree.feature.scenarios.forEach((scenario) => {
          if (!feature.scenarios.find((s) => s.id == scenario.id)) {
            updates.push(
              new Promise((resolve, reject) => {
                this.removeScenario(scenario, false).subscribe(resolve, reject);
              })
            );
          }
        });

        await Promise.all(updates);

        if (newFeature.stageId != this.activeStage.id) {
          const newStage = this.findStageById(newFeature.stageId);
          this.setActiveStage(newStage.stage, newFeature);
        } else {
          this.setActiveStage(this.activeStage, newFeature);
        }

        if (hasSpinner)
          this.spinner.hide().then();
        observer.next('Feature has been edited successfully.');
        observer.complete();
      }).catch(err => {
        if (hasSpinner)
          this.spinner.hide().then();
        observer.error(err);
        observer.complete();
      });
    });
  }

  removeFeature(feature: FeatureModel) {
    return new Observable(observer => {
      this.featureSrv.removeFeature(feature).subscribe(() => {
        if (feature.stageId == this.activeStage.id) {
          // this.activeStage.features = this.activeStage.features.filter((f) => f.id != feature.id);
          this.setActiveStage(this.activeStage);
        }

        observer.next('Feature has been removed successfully.');
        observer.complete();
      }, (error) => {
        observer.error(error.message || 'Removing feature has been failed.');
        observer.complete();
      });
    });
  }

  createScenario(scenario: ScenarioModel, hasSpinner = true) {
    if (hasSpinner)
      this.spinner.show().then();

    return new Observable(observer => {
      this.featureSrv.createScenario(scenario, false).subscribe(async (newScenario) => {
        const feature = this.activeFeature;
        feature.scenarios.push(newScenario);

        for (const step of scenario.steps) {
          await new Promise((resolve) => {
            step.scenarioId = newScenario.id;
            this.createStep(newScenario, step, false).subscribe(resolve);
          });
        }

        this.activeFeature = feature;
        if (hasSpinner)
          this.spinner.hide().then();
        observer.next('New scenario has been created successfully.');
        observer.complete();
      }, (error) => {
        if (hasSpinner)
          this.spinner.hide().then();
        observer.error(error.message || 'Creating new scenario has been failed.');
        observer.complete();
      });
    });
  }

  editScenario(scenario: ScenarioModel, orgScenario: ScenarioModel, hasSpinner = true) {
    if (hasSpinner)
      this.spinner.show().then();

    return new Observable(observer => {
      new Promise((resolve, reject) => {
        if (scenario.isEqual(orgScenario)) {
          resolve(scenario);
          return;
        }

        this.featureSrv.editScenario(scenario, false).subscribe((newScenario) => {
          newScenario.id = scenario.id;
          resolve(newScenario);
        }, (error) => {
          reject(error.message || 'Editing scenario has been failed.');
        });
      }).then(async (newScenario: ScenarioModel) => {
        const removePromises = [];
        orgScenario.steps.forEach((step) => {
          removePromises.push(
            () => new Promise((resolve) => {
              this.removeStep(newScenario, step, false).subscribe(resolve, resolve);
            })
          );
        });
        const createPromises = [];
        scenario.steps.forEach((step) => {
          createPromises.push(
            () => new Promise((resolve, reject) => {
              step.scenarioId = newScenario.id as string;
              this.createStep(newScenario, step, false).subscribe(resolve, reject);
            })
          );
        });

        await Promise.all(removePromises.map((promise) => promise()));
        for (const promise of createPromises)
          await promise();

        this.activeFeature.scenarios = this.activeFeature.scenarios.map((s) => s.id == newScenario.id ? newScenario : s);

        if (hasSpinner)
          this.spinner.hide().then();
        observer.next('Scenario has been edited successfully.');
        observer.complete();
      }).catch(err => {
        if (hasSpinner)
          this.spinner.hide().then();
        observer.error(err);
        observer.complete();
      });
    });
  }

  removeScenario(scenario: ScenarioModel, hasSpinner = true) {
    return new Observable(observer => {
      this.featureSrv.removeScenario(scenario, hasSpinner).subscribe(() => {
        this.activeFeature = new FeatureModel({
          ...this.activeFeature,
          scenarios: this.activeFeature.scenarios.filter((s) => s.id !== scenario.id),
        });
        observer.next('Scenario has been removed successfully.');
        observer.complete();
      }, (error) => {
        observer.error(error.message || 'Removing scenario has been failed.');
        observer.complete();
      });
    });
  }

  duplicateScenario(scenario: ScenarioModel, hasSpinner = true) {
    return new Observable(observer => {
      this.featureSrv.duplicateScenario(scenario, hasSpinner).subscribe(() => {
        this.setActiveFeature(new FeatureModel({id: scenario.featureId}));
        observer.next('Scenario has been duplicated successfully.');
        observer.complete();
      }, (error) => {
        observer.error(error.message || 'Duplicating scenario has been failed.');
        observer.complete();
      });
    });
  }

  autoGenerateScenario(feature: FeatureModel, hasSpinner = true) {
    if (hasSpinner)
      this.spinner.show().then();

    return new Observable(observer => {
      this.featureSrv.autoGenerateScenario(feature, false).subscribe(async (newScenario) => {
        if (hasSpinner)
          this.spinner.hide().then();
        if (!newScenario) {
          observer.next(null);
        } else {
          this.setActiveFeature(feature);
          observer.next('Scenario has been generated successfully.');
        }
        observer.complete();
      }, (error) => {
        if (hasSpinner)
          this.spinner.hide().then();
        observer.error(error.message || 'Generating scenario has been failed.');
        observer.complete();
      });
    });
  }

  createStep(scenario: ScenarioModel, step: StepModel, hasSpinner = true) {
    return new Observable(observer => {
      this.featureSrv.createStep(scenario, step, hasSpinner).subscribe((newStep) => {
        scenario.steps.push(newStep);

        observer.next('New step has been created successfully.');
        observer.complete();
      }, (error) => {
        observer.error(error.message || 'Creating new step has been failed.');
        observer.complete();
      });
    });
  }

  editStep(scenario: ScenarioModel, step: StepModel, orgStep: StepModel) {
    return new Observable(observer => {
      if (step.isEqual(orgStep)) {
        observer.next(step);
        return;
      }

      this.featureSrv.editStep(scenario, step).subscribe((newStep) => {
        newStep.id = step.id;
        scenario.steps = scenario.steps.map((s) => s.id == newStep.id ? newStep : s);
        observer.next('Step has been edited successfully.');
        observer.complete();
      }, (error) => {
        observer.error(error.message || 'Editing step has been failed.');
        observer.complete();
      });
    });
  }

  removeStep(scenario: ScenarioModel, step: StepModel, hasSpinner = true) {
    return new Observable(observer => {
      this.featureSrv.removeStep(scenario, step, hasSpinner).subscribe(() => {
        scenario.steps = scenario.steps.filter((s) => s.id !== step.id);
        observer.next('Step has been removed successfully.');
        observer.complete();
      }, (error) => {
        observer.error(error.message || 'Removing step has been failed.');
        observer.complete();
      });
    });
  }

  getFeatureById(id: string): FeatureModel {
    for (const trial of this.trials) {
      for (const stage of trial.stages) {
        for (const feature of stage.features) {
          if (feature.id == id) {
            return feature;
          }
        }
      }
    }

    return null;
  }

  setEditingFeature(feature: FeatureModel) {
    if (this.editingFeature == feature)
      return;

    this.editingFeature = new FeatureModel({...feature});
  }

  saveEditingFeature() {
    if (this.editingFeature.id)
      return this.editFeature(this.editingFeature);
    return this.createFeature(this.editingFeature);
  }

  createPredefinedGlobalFeatures(stage: StageModel): Observable<any> {
    const feature = {
      name: 'Login',
      type: 'display_message',
      stageId: stage.id,
      description: 'As a Participant\nI want to verify my Participant ID\nSo that I can login',
      scenarios: [
        {
          name: 'Valid Participant ID',
          description: 'When Participant enters valid Participant ID.',
          data: [['participant_id'], ['']],
          order: 1,
          steps: [
            {
              action: 'Participant is on Verify Participant ID screen',
              condition: 'Given',
            },
            {
              action: 'Participant enters Valid "<participant_id>"',
              condition: 'When'
            },
            {
              action: 'Success message should appear "PARTICIPANT ID IS VERIFIED"',
              condition: 'Then'
            }
          ]
        },
        {
          name: 'Create Password with matching Password and Confirm Password',
          description: 'When Participant enters exactly same passwords in Password and confirm password fields',
          data: [['password', 'confirm_password'], ['Claimit@1234', 'Claimit@1234']],
          order: 2,
          steps: [
            {
              action: 'Participant has already verified the Participant ID',
              condition: 'Given'
            },
            {
              action: 'Participant enters "<password>" in INPUT_ENTER_PASSWORD_TXTFIELD',
              condition: 'When'
            },
            {
              action: 'Participant enters "<confirm_password>" in INPUT_CONFIRM_PASSWORD_TXTFIELD',
              condition: 'And'
            },
            {
              action: 'Participant taps on CONTINUE <PASSWORD_SIGN_IN_BUTTON>',
              condition: 'And'
            },
            {
              action: 'Participant should be redirected to Dashboard',
              condition: 'Then'
            }
          ]
        },
      ]
    };
    const loginFeature = new FeatureModel(feature);
    return this.createFeature(loginFeature);
  }
}
