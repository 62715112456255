export default class TestDeviceModel {
  id: number;
  resolution: string;
  cpu:	string;
  modelNumber:	string;
  name:	string;
  platformName:	string;
  freeTrial:	boolean;
  testEnvironment: {
    id: number;
    version: string;
    platform: string;
  };
  multipleBrowsers: {
    id: number;
    name: string;
  }[];
  available: boolean;

  constructor(init: any = {}) {
    this.id = init.id || null;
    this.resolution = init.resolution || '';
    this.cpu = init.cpu || '';
    this.modelNumber = init.model_number || init.modelNumber || '';
    this.name = init.name || '';
    this.platformName = init.platform_name || init.platformName || '';
    this.freeTrial = init.free_trial || init.freeTrial || false;
    this.testEnvironment = init.test_environment || init.testEnvironment || {
      id: null,
      version: '',
      platform: '',
    };
    this.multipleBrowsers = init.multiple_browsers || init.multipleBrowsers || [];
    this.available = init.available || false;
  }
}
