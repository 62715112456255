import {HttpClient} from '@angular/common/http';
import {environment} from '@env';
import {Injectable} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {Observable} from 'rxjs';
import {ApiService} from '@shared/services/api.service';
import TestDeviceModel from '@shared/models/test-device.model';
import TestModel from '@shared/models/test.model';
import UploadBuildHistoryModel from '@shared/models/upload-build-history.model';

@Injectable()
export class TestService extends ApiService {
  constructor(
    protected http: HttpClient,
    protected spinner: NgxSpinnerService
  ) {
    super(http, spinner);
  }

  getTestingDevices(): Observable<any> {
    return new Observable(observer => {
      this.get(`${environment.APIServer}/testingbot/devices`).subscribe(response => {
        observer.next(response.map((record) => new TestDeviceModel(record)));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getTests(params?, hasSpinner = true): Observable<any> {
    return new Observable(observer => {
      this.get(`${environment.APIServer}/tests`, params, hasSpinner).subscribe(response => {
        observer.next(response);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getTest(testId: string, params?, hasSpinner = true): Observable<any> {
    return new Observable(observer => {
      this.get(`${environment.APIServer}/tests/${testId}`, params, hasSpinner).subscribe(response => {
        observer.next(new TestModel(response));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  removeTest(testId: string): Observable<any> {
    return new Observable(observer => {
      this.delete(`${environment.APIServer}/tests/${testId}`).subscribe(response => {
        observer.next(response);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  downloadScreenshots(testId: string, params?, hasSpinner = true): Observable<boolean> {
    return new Observable(observer => {
      this.get(`${environment.APIServer}/tests/${testId}/screenshots`, params, hasSpinner).subscribe(response => {
        observer.next(true);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  uploadBuildApp(trialId: string, platform: string, type: string, file, hasSpinner = true): Observable<any> {
    let data;
    if (type === 'file') {
      data = new FormData();
      data.append('trial', trialId);
      data.append('platform', platform);
      data.append('type', type);
      data.append('file', file);
    } else {
      data = {
        trial: trialId,
        platform,
        type,
        file,
      };
    }

    return new Observable(observer => {
      this.post(`${environment.APIServer}/testingbot/build-app`, data, hasSpinner).subscribe(response => {
        observer.next(response);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getUploadBuildHistory(hasSpinner = true): Observable<UploadBuildHistoryModel[]> {
    return new Observable(observer => {
      this.get(`${environment.APIServer}/testingbot/upload-build-history`, {}, hasSpinner).subscribe(response => {
        observer.next(response.map((item) => new UploadBuildHistoryModel(item)));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }
}
