import StageModel from '@shared/models/stage.model';
import ScenarioModel from '@shared/models/scenario.model';
import moment from 'moment';
import {formatTag, isEqual} from '@shared/helpers/util';

export default class FeatureModel {
  id: string;
  name: string;
  type: string;
  description: string;
  tags: string[];
  scenarios: ScenarioModel[];
  stageId: string;
  selected?: boolean;
  status: string;
  stageModel?: StageModel;
  backendOnly: boolean;

  constructor(init: any = {}) {
    if (init.stage && typeof init.stage === 'object') {
      init.stageModel = init.stage;
      init.stage = init.stage._id;
    }
    this.id = init._id || init.id || null;
    this.name = (init.name || '').replace(/^(STR|RTR)_/ig, '');
    this.type = init.type || '';
    this.description = init.description || 'As a \nI want \nSo that ';
    this.tags = (init.tags && init.tags.length) ? init.tags : (init.Tags || []);
    this.scenarios = (init.scenarios || []).map((scenario) => new ScenarioModel(scenario));
    this.stageId = init.stage || init.stageId || null;
    this.status = init.status;
    this.stageModel = init.stageModel || null;
    this.backendOnly = init.backendOnly;
  }

  toFeatureFormat() {
    let str = `#\n# Feature: ${this.name}\n#\n# Created with BDD Editor\n#\n\n`;
    str += this.tags.map((tag) => formatTag(tag)).join(' ');
    str += `\nFeature:${this.name}`;
    str += `\n${this.description.replace(/^(.*)/mg, '  $1')}`;
    if (this.backendOnly !== true) {
      str += `\n\n  Background:\n    Given Reset the application`;
    }
    this.scenarios.forEach((scenario) => {
      str += scenario.toFeatureFormat();
    });
    return str;
  }

  isEqual(other: FeatureModel): boolean {
    return this.id == other.id && this.name == other.name && this.type == other.type
      && this.description == other.description && isEqual(this.tags, other.tags);
  }
}
