import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {FullLayoutComponent} from './full-layout/full-layout.component';
import {RouterModule} from '@angular/router';
import {AuthLayoutComponent} from './auth-layout/auth-layout.component';
import {MainMenuComponent} from './main-menu/main-menu.component';
import {SharedModule} from '@shared/shared.module';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    FullLayoutComponent,
    AuthLayoutComponent,
    MainMenuComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
  ]
})
export class LayoutModule {
}
