import {Component, OnInit} from '@angular/core';
import {TrialStore} from '@shared/store/trial.store';
import {ExportService} from '@shared/services/export.service';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {UploadBuildHistoryModalComponent} from '@shared/components/upload-build-history-modal/upload-build-history-modal.component';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  constructor(
    public trialStore: TrialStore,
    private exportSrv: ExportService,
    private toastSrv: ToastrService,
    private matDialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.trialStore.loadTrials();
  }

  onTrialSelect($event) {
    const trialId = $event.target.value;
    const trial = this.trialStore.trials.find((t) => t.id == trialId);
    this.trialStore.setActiveTrial(trial);
  }

  onLogOut() {
    this.trialStore.logout();
  }

  onExportReport(backendOnly = false) {
    this.exportSrv.downloadReportPdf(this.trialStore.activeTrial, backendOnly).subscribe(() => {
      this.toastSrv.success('Test report has been exported.');
    }, (err) => {
      this.toastSrv.error(err.msg || 'Export report failed');
    });
  }

  onUploadBuild() {
    $('#upload-build-modal').modal('show');
  }

  onViewUploadBuildHistory() {
    this.matDialog.open(UploadBuildHistoryModalComponent);
  }
}
