import {HttpClient} from '@angular/common/http';
import {environment} from '@env';
import {Injectable} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {Observable} from 'rxjs';
import {ApiService} from '@shared/services/api.service';
import TrialModel from '@shared/models/trial.model';
import StageModel from '@shared/models/stage.model';
import ScenarioModel from '@shared/models/scenario.model';
import FeatureModel from '@shared/models/feature.model';
import StepModel from '@shared/models/step.model';
import TestDeviceModel from '@shared/models/test-device.model';

@Injectable()
export class FeatureService extends ApiService {
  constructor(
    protected http: HttpClient,
    protected spinner: NgxSpinnerService
  ) {
    super(http, spinner);
  }

  getTrials(hasSpinner = true): Observable<any> {
    return new Observable(observer => {
      this.get(`${environment.APIServer}/trials`, null, hasSpinner).subscribe(response => {
        observer.next(response.map((record) => new TrialModel(record)));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getTrial(trialId: string, hasSpinner = true): Observable<any> {
    return new Observable(observer => {
      this.get(`${environment.APIServer}/trials/${trialId}`, null, hasSpinner).subscribe(response => {
        observer.next(new TrialModel(response));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getStages(trial: TrialModel, hasSpinner = true): Observable<any> {
    return new Observable(observer => {
      this.get(`${environment.APIServer}/trials/${trial.id}/stages`, null, hasSpinner).subscribe(response => {
        observer.next(response.map((record) => new StageModel(record)));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getStage(stageId: string, hasSpinner = true): Observable<any> {
    return new Observable(observer => {
      this.get(`${environment.APIServer}/stages/${stageId}`, null, hasSpinner).subscribe(response => {
        observer.next(new StageModel(response));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getFeatures(stage: StageModel, hasSpinner = true): Observable<any> {
    return new Observable(observer => {
      this.get(`${environment.APIServer}/features?stageId=${stage.id}&limit=1000`, null, hasSpinner).subscribe(response => {
        observer.next(response.docs.map((record) => new FeatureModel(record)));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getFeature(featureId: string, hasSpinner = true): Observable<any> {
    return new Observable(observer => {
      this.get(`${environment.APIServer}/features/${featureId}`, null, hasSpinner).subscribe(response => {
        observer.next(new FeatureModel(response));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  createFeature(feature: FeatureModel, hasSpinner = true): Observable<any> {
    return new Observable(observer => {
      this.post(`${environment.APIServer}/features`, {
        stage: feature.stageId,
        name: feature.name,
        description: feature.description,
        type: feature.type,
        tags: feature.tags,
      }, hasSpinner).subscribe(response => {
        observer.next(new FeatureModel(response));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  editFeature(feature: FeatureModel, hasSpinner = true): Observable<any> {
    return new Observable(observer => {
      this.put(`${environment.APIServer}/features/${feature.id}`, {
        stage: feature.stageId,
        name: feature.name,
        description: feature.description,
        type: feature.type,
        tags: feature.tags,
      }, hasSpinner).subscribe(response => {
        observer.next(new FeatureModel(response));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  removeFeature(feature: FeatureModel, hasSpinner = true): Observable<any> {
    return new Observable(observer => {
      this.delete(`${environment.APIServer}/features/${feature.id}`, {}, hasSpinner).subscribe(response => {
        observer.next(response);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getScenarios(feature: FeatureModel, hasSpinner = true): Observable<any> {
    return new Observable(observer => {
      this.get(`${environment.APIServer}/features/${feature.id}/scenarios`, null, hasSpinner).subscribe(response => {
        observer.next(response.map((s) => new ScenarioModel(s)));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  createScenario(scenario: ScenarioModel, hasSpinner = true): Observable<any> {
    return new Observable(observer => {
      this.post(`${environment.APIServer}/features/${scenario.featureId}/scenarios`, {
        name: scenario.name,
        description: scenario.description,
        tags: scenario.tags,
        data: scenario.data,
        order: scenario.order,
      }, hasSpinner).subscribe(response => {
        observer.next(new ScenarioModel(response));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  editScenario(scenario: ScenarioModel, hasSpinner = true): Observable<any> {
    return new Observable(observer => {
      this.put(`${environment.APIServer}/features/${scenario.featureId}/scenarios/${scenario.id}`, {
        name: scenario.name,
        description: scenario.description,
        tags: scenario.tags,
        data: scenario.data,
        order: scenario.order,
      }, hasSpinner).subscribe(response => {
        observer.next(new ScenarioModel(response));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  removeScenario(scenario: ScenarioModel, hasSpinner = true): Observable<any> {
    return new Observable(observer => {
      this.delete(`${environment.APIServer}/features/${scenario.featureId}/scenarios/${scenario.id}`, {}, hasSpinner).subscribe(response => {
        observer.next(response);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  duplicateScenario(scenario: ScenarioModel, hasSpinner = true): Observable<any> {
    return new Observable(observer => {
      this.post(`${environment.APIServer}/features/${scenario.featureId}/scenarios/${scenario.id}/duplicate`, {
        name: `${scenario.name} copy`
      }, hasSpinner).subscribe(response => {
        observer.next(response);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  autoGenerateScenario(feature: FeatureModel, hasSpinner = true): Observable<any> {
    return new Observable(observer => {
      this.post(`${environment.APIServer}/features/${feature.id}/autoGenerateScenario`, {}, hasSpinner).subscribe(response => {
        if (!response) {
          observer.next(false);
        } else {
          observer.next(new ScenarioModel(response));
        }
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getSteps(scenario: ScenarioModel, hasSpinner = true): Observable<any> {
    return new Observable(observer => {
      this.get(`${environment.APIServer}/features/${scenario.featureId}/scenarios/${scenario.id}/steps`, null, hasSpinner).subscribe(response => {
        observer.next(response.map((s) => new StepModel(s)));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  createStep(scenario: ScenarioModel, step: StepModel, hasSpinner = true): Observable<any> {
    return new Observable(observer => {
      this.post(`${environment.APIServer}/features/${scenario.featureId}/scenarios/${scenario.id}/steps`, {
        condition: step.condition,
        action: step.action,
        dataRows: step.data.length,
        dataColumns: step.data.length ? step.data[0].length : 0,
        data: step.data,
      }, hasSpinner).subscribe(response => {
        observer.next(new StepModel(response));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  editStep(scenario: ScenarioModel, step: StepModel, hasSpinner = true): Observable<any> {
    return new Observable(observer => {
      this.put(`${environment.APIServer}/features/${scenario.featureId}/scenarios/${scenario.id}/steps/${step.id}`, {
        condition: step.condition,
        action: step.action,
        dataRows: step.data.length,
        dataColumns: step.data.length ? step.data[0].length : 0,
        data: step.data,
      }, hasSpinner).subscribe(response => {
        observer.next(new StepModel(response));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  removeStep(scenario: ScenarioModel, step: StepModel, hasSpinner = true): Observable<any> {
    return new Observable(observer => {
      this.delete(`${environment.APIServer}/features/${scenario.featureId}/scenarios/${scenario.id}/steps/${step.id}`, {}, hasSpinner).subscribe(response => {
        observer.next(response);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  runFeatureTest(feature: FeatureModel, scenarios: string[] = [], device?: TestDeviceModel, hasSpinner = true): Observable<any> {
    return new Observable(observer => {
      this.post(`${environment.APIServer}/features/${feature.id}/run`, {
        device: device?.id,
        scenarios,
      }, hasSpinner).subscribe(response => {
        observer.next(response);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getLatestTest(feature: FeatureModel, hasSpinner = true): Observable<any> {
    return new Observable(observer => {
      this.get(`${environment.APIServer}/features/${feature.id}/latestTest`, null, hasSpinner).subscribe(response => {
        observer.next(response);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  getAvailabilityTestingbot(hasSpinner = true): Observable<boolean> {
    return new Observable(observer => {
      this.get(`${environment.APIServer}/testingbot/isAvailable`, null, hasSpinner).subscribe(response => {
        observer.next(response.isAvailable);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }
}
