import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-tags-input',
  templateUrl: './tags-input.component.html'
})
export class TagsInputComponent implements OnInit, OnChanges {
  @Input() tags: string[];
  @Output() tagsChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  input = '';

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.input = '';
  }

  onAddTag() {
    if (this.input && this.tags.indexOf(this.input) == -1) {
      this.tagsChange.emit([...this.tags, this.input]);
    }
    this.input = '';
  }

  onKeyDown($event) {
    if ($event.key === 'Enter' && this.input) {
      $event.preventDefault();
      this.onAddTag();
    }

    if ($event.key === 'Backspace' && !this.input && this.tags.length) {
      $event.preventDefault();
      this.tagsChange.emit(this.tags.slice(0, this.tags.length - 1));
    }
  }

  onRemoveTag(tag) {
    this.tagsChange.emit(this.tags.filter((t) => t !== tag));
  }
}
