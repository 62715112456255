import {AfterViewInit, Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ImagePreviewModalComponent} from '@shared/components/image-preview-modal/image-preview-modal.component';

declare var $: any;

@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html'
})
export class ImageCarouselComponent implements OnInit, AfterViewInit, OnDestroy {

  @HostBinding('class') @Input('class') classList: string;

  @ViewChild('carouselWrapper') carouselWrapper: ElementRef;

  @Input() images: string[] = [];
  @Input() showPreviewModal = false;
  @Input() thumbnailWidth = 50;
  @Input() thumbnailHeight = 50;

  resizeHandler;

  indicatorInfo = {
    from: 0,
    to: 0,
    count: 0,
  };
  activeSlide = 0;

  get indicators(): string[] {
    return this.images.slice(this.indicatorInfo.from, this.indicatorInfo.to);
  }

  constructor(
    public dialog: MatDialog,
  ) {
    this.resizeHandler = this.onResize.bind(this);
  }

  ngOnInit(): void {
    window.addEventListener('resize', this.resizeHandler);
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.resizeHandler);
  }

  ngAfterViewInit(): void {
    this.onResize();

    $('#images-carousel').on('slide.bs.carousel', (e) => {
      this.activeSlide = e.to;
    });
  }

  onResize() {
    if (!this.carouselWrapper || !this.images)
      return;

    let count = Math.floor(this.carouselWrapper.nativeElement.clientWidth / this.thumbnailWidth);
    if (this.images.length > count)
      count -= 2;
    else
      count = this.images.length;
    if (count < 1)
      count = 1;

    this.indicatorInfo = {
      from: this.indicatorInfo.from,
      to: this.indicatorInfo.from + count,
      count,
    };
  }

  onPrevThumb() {
    if (this.indicatorInfo.from > 0) {
      this.indicatorInfo = {
        from: this.indicatorInfo.from - 1,
        to: this.indicatorInfo.to - 1,
        count: this.indicatorInfo.count,
      };
    }
  }

  onNextThumb() {
    if (this.indicatorInfo.to < this.images.length) {
      this.indicatorInfo = {
        from: this.indicatorInfo.from + 1,
        to: this.indicatorInfo.to + 1,
        count: this.indicatorInfo.count,
      };
    }
  }

  onSelectThumb(id) {
    $('#images-carousel').carousel(id);
  }

  onPreviewImage(image) {
    if (this.showPreviewModal) {
      this.dialog.open(ImagePreviewModalComponent, {
        panelClass: 'transparent-modal',
        data: {image, images: this.images},
      });
    }
  }
}
