import {Component, OnInit} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  consentCookie: boolean;

  constructor(
    private cookieSrv: CookieService
  ) {
  }

  ngOnInit() {
    if (this.cookieSrv.get('consentCookie')) {
      this.consentCookie = false;
    } else {
      this.consentCookie = false;
    }
  }

  consent() {
    this.consentCookie = false;
    this.cookieSrv.set('consentCookie', 'true');
  }
}
