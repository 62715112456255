import {HttpClient} from '@angular/common/http';
import {environment} from '@env';
import {Injectable} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {Observable} from 'rxjs';
import {ApiService} from '@shared/services/api.service';
import UserModel from '@shared/models/user.model';

@Injectable()
export class AuthService extends ApiService {
  constructor(
    protected http: HttpClient,
    protected spinner: NgxSpinnerService
  ) {
    super(http, spinner);
  }

  login(token: string, hasSpinner = true): Observable<UserModel> {
    return new Observable(observer => {
      this.post(`${environment.APIServer}/auth/login`, {token}, hasSpinner).subscribe(response => {
        observer.next(new UserModel(response));
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  logout(hasSpinner = true): Observable<boolean> {
    const token = localStorage.getItem('token');
    return new Observable(observer => {
      this.request('PUT', `${environment.authServer}/api/servicesExpireSession`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: {
          session_expired: 1,
        },
      }, hasSpinner).subscribe(response => {
        observer.next(true);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  setActiveTrial(trial: string, hasSpinner = true): Observable<void> {
    return new Observable(observer => {
      this.post(`${environment.APIServer}/auth/active-trial`, {trial}, hasSpinner).subscribe(() => {
        observer.next();
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }
}
