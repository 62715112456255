import UserModel from '@shared/models/user.model';

export default class UploadBuildHistoryModel {
  trial: {
    _id: string;
    name: string;
  };
  platform: string;
  buildName: string;
  uploadedBy: UserModel;
  createdAt: string;
  updatedAt: string;

  constructor(init: any = {}) {
    this.trial = init.trial || null;
    this.platform = init.platform || '';
    this.buildName = init.buildName || '';
    this.uploadedBy = new UserModel(init.uploadedBy);
    this.createdAt = init.createdAt;
    this.updatedAt = init.updatedAt;
  }
}
