import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  headers: HttpHeaders;

  constructor(
    protected http: HttpClient,
    protected spinner: NgxSpinnerService
  ) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.set('Content-Type', `application/json`);
  }

  protected get(url, params?, hasSpinner = true): Observable<any> {
    if (hasSpinner) {
      this.spinner.show().then();
    }
    return new Observable((observer) => {
      this.http.get<any>(url, {headers: this.headers, params}).subscribe(response => {
        if (hasSpinner) {
          this.spinner.hide().then();
        }
        observer.next(response);
        observer.complete();
      }, error => {
        if (hasSpinner) {
          this.spinner.hide().then();
        }
        observer.error(error);
        observer.complete();
      });
    });
  }

  protected post(url, body, hasSpinner = true): Observable<any> {
    if (hasSpinner) {
      this.spinner.show().then();
    }
    return new Observable(observer => {
      this.http.post<any>(url, body).subscribe(response => {
        if (hasSpinner) {
          this.spinner.hide().then();
        }
        observer.next(response);
        observer.complete();
      }, error => {
        if (hasSpinner) {
          this.spinner.hide().then();
        }
        observer.error(error);
        observer.complete();
      });
    });
  }

  protected put(url, body, hasSpinner = true): Observable<any> {
    if (hasSpinner) {
      this.spinner.show().then();
    }
    return new Observable(observer => {
      this.http.put<any>(url, body, {headers: this.headers}).subscribe(response => {
        if (hasSpinner) {
          this.spinner.hide().then();
        }
        observer.next(response);
        observer.complete();
      }, error => {
        if (hasSpinner) {
          this.spinner.hide().then();
        }
        observer.error(error);
        observer.complete();
      });
    });
  }

  protected delete(url, body = null, hasSpinner = true): Observable<any> {
    if (hasSpinner) {
      this.spinner.show().then();
    }
    return new Observable(observer => {
      this.http.request('delete', url, {headers: this.headers, body: body}).subscribe(response => {
        if (hasSpinner) {
          this.spinner.hide().then();
        }
        observer.next(response);
        observer.complete();
      }, error => {
        if (hasSpinner) {
          this.spinner.hide().then();
        }
        observer.error(error);
        observer.complete();
      });
    });
  }

  protected request(method, url, options = {}, hasSpinner = true): Observable<any> {
    if (hasSpinner) {
      this.spinner.show().then();
    }
    return new Observable(observer => {
      this.http.request<any>(method, url, options).subscribe(response => {
        if (hasSpinner) {
          this.spinner.hide().then();
        }
        observer.next(response);
        observer.complete();
      }, error => {
        if (hasSpinner) {
          this.spinner.hide().then();
        }
        observer.error(error);
        observer.complete();
      });
    });
  }
}
