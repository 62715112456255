import FeatureModel from '@shared/models/feature.model';
import TestDeviceModel from '@shared/models/test-device.model';
import UserModel from '@shared/models/user.model';
import ScenarioModel from '@shared/models/scenario.model';

export default class TestModel {
  id: string;
  status: string;
  deviceId:	number;
  featureId: string;
  scenarios: ScenarioModel[];
  createdAt: string;
  updatedAt: string;
  result: {
    browser: string;
    browser_version: string;
    build: any;
    completed_at: string;
    created_at: string;
    duration: number;
    extra: string;
    groups: any[];
    id: number;
    logs: {
      appium: string;
      ios: string;
      selenium: string;
      vm: string;
    },
    name: string;
    os: string;
    session_id: string;
    state: string;
    status_message: string;
    steps: {
      arguments: string;
      command: string;
      response: string;
      time: number;
    }[];
    success: boolean;
    thumbs: any[];
    type: string;
    unknown: boolean;
    video: string;
  };
  resultsByScenario: {
    failed: boolean;
    isPassed: boolean;
    name: string;
    passed: number;
    skipped: number;
    totalSteps: number;
  }[];
  device: TestDeviceModel;
  featureModel?: FeatureModel;
  user: UserModel;

  constructor(init: any = {}) {
    if (init.feature && typeof init.feature === 'object') {
      init.featureModel = new FeatureModel(init.feature);
      init.feature = init.feature._id;
    }
    this.id = init._id || init.id || null;
    this.status = init.status || '';
    this.deviceId = init.deviceId || null;
    this.featureId = init.feature || init.featureId || '';
    this.scenarios = init.scenarios;
    this.createdAt = init.createdAt || '';
    this.updatedAt = init.updatedAt || '';
    this.result = init.result || {};
    this.device = new TestDeviceModel(init.device || {});
    this.featureModel = init.featureModel || null;
    this.user = new UserModel(init.user || {});
    this.resultsByScenario = init.resultsByScenario || [];
  }
}
