import StepModel from '@shared/models/step.model';
import {formatTableAsText, formatTag, isEqual} from '@shared/helpers/util';

export default class ScenarioModel {
  id: string | number;
  name: string;
  description: string;
  steps: StepModel[];
  tags: string[];
  data: string[][];
  order: number;
  featureId: string;

  // extra fields
  selected?: boolean;

  constructor(init: any = {}) {
    this.id = init._id || init.id || null;
    this.name = init.name || '';
    this.description = init.description || '';
    this.steps = (init.steps || []).map((step) => new StepModel(step));
    this.tags = init.tags || [];
    this.data = init.data || [];
    this.order = init.order || null;
    this.featureId = init.feature || init.featureId || null;
  }

  toFeatureFormat() {
    let str = '\n\n ';
    this.tags.forEach((tag) => {
      str += ` ${formatTag(tag)}`;
    });
    str += `\n  Scenario${this.data.length ? ' Outline' : ''}: ${this.name}`;
    this.steps.forEach((step) => {
      str += step.toFeatureFormat();
    });

    if (this.data.length) {
      str += '\n\n    Examples:\n';
      str += formatTableAsText(this.data).replace(/^(.*)$/gm, '      $1');
    }
    return str;
  }

  isEqual(other: ScenarioModel): boolean {
    return this.id == other.id && this.name == other.name && this.description == other.description
      && isEqual(this.tags, other.tags) && isEqual(this.data, other.data);
  }
}
