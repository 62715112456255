import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TestService} from '@shared/services/test.service';
import {TrialStore} from '@shared/store/trial.store';

declare var $: any;

@Component({
  selector: 'app-upload-build-modal',
  templateUrl: './upload-build-modal.component.html'
})
export class UploadBuildModalComponent implements OnInit {
  @Output() choose: EventEmitter<string> = new EventEmitter<string>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  platforms = [
    { name: 'iOS', value: 'ios' },
    { name: 'Android', value: 'android' },
  ];
  types = [
    { name: 'Build', value: 'file' },
    { name: 'URL', value: 'url' },
  ];

  platform = 'android';
  type = 'file';
  file = null;
  fileName = '';
  url = '';

  get formValid(): boolean {
    if (this.type === 'file')
      return !!this.file;
    return !!this.url.trim();
  }

  constructor(
    public trialStore: TrialStore,
    private testSrv: TestService,
    private toastSrv: ToastrService,
  ) {
    this.onReset();
  }

  ngOnInit(): void {
  }

  onFieldChange(field, e) {
    this[field] = e.target.value;
  }

  onSelectFile(e) {
    this.file = e.target.files[0];
  }

  onUpload() {
    this.testSrv.uploadBuildApp(
      this.trialStore.activeTrial.id,
      this.platform,
      this.type,
      this.type === 'file' ? this.file : this.url,
    ).subscribe(({ url }) => {
      this.toastSrv.success('Build app has been uploaded');
      if (this.platform === 'ios')
        this.trialStore.activeTrial.iosApp = url;
      else
        this.trialStore.activeTrial.androidApp = url;

      this.onClose();
    }, (err) => {
      this.toastSrv.error(err.msg || 'Upload build app failed');
    });
  }

  onCancel() {
    this.cancel.emit();
    this.onClose();
  }

  onReset() {
    this.platform = 'android';
    this.type = 'file';
    this.file = null;
    this.fileName = '';
    this.url = '';
  }

  onClose() {
    this.onReset();
    $('#upload-build-modal').modal('hide');
  };
}
