import FeatureModel from '@shared/models/feature.model';
import TrialModel from '@shared/models/trial.model';

export default class StageModel {
  id: string;
  name: string;
  features: FeatureModel[];
  trialId: string;
  trialModel?: TrialModel;

  constructor(init: any = {}) {
    if (init.trial && typeof init.trial === 'object') {
      init.trialModel = init.trial;
      init.trial = init.trial._id;
    }
    this.id = init._id || init.id || null;
    this.name = (init.name || '').replace(/^(STR|RTR)_/ig, '');
    this.features = (init.features || []).map((feature) => new FeatureModel(feature));
    this.trialId = init.trial || init.trialId || null;
    this.trialModel = init.trialModel || null;
  }

  public get isGlobalStage(): boolean {
    return this.name == 'Global';
  }
}
