import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {TrialStore} from '@shared/store/trial.store';
import {environment} from '@env';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(
    public trialStore: TrialStore,
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.trialStore.user)
      return true;

    window.location.href = environment.loginUrl || 'http://uat.v1.claimitadmin.obviohealth.net/login';
    return false;
  }
}
