import {HttpClient} from '@angular/common/http';
import {environment} from '@env';
import {Injectable} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {Observable} from 'rxjs';
import {ApiService} from '@shared/services/api.service';
import TrialModel from '@shared/models/trial.model';

@Injectable()
export class ExportService extends ApiService {
  constructor(
    protected http: HttpClient,
    protected spinner: NgxSpinnerService
  ) {
    super(http, spinner);
  }

  downloadReportPdf(trial: TrialModel, backendOnly = false): Observable<void> {
    return new Observable(observer => {
      this.post(`${environment.APIServer}/export/report-pdf/${trial.id}`, { backendOnly }).subscribe(response => {
        this.downloadFile(
          `${environment.APIServer}/export/download-report/${response.fileName}`,
          `${trial.name}.pdf`
        );
        observer.next();
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  downloadFile(url: string, fileName: string) {
    const a: any = document.createElement('a');
    a.style = 'display: none';
    a.href = url;
    a.download = fileName;
    a.target = '_blank';

    document.body.appendChild(a);
    a.click();
  }
}
