import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ApiService} from '@shared/services/api.service';
import {TagsInputComponent} from '@shared/components/tags-input/tags-input.component';
import {FormsModule} from '@angular/forms';
import {SafeHtmlPipe} from '@shared/pipes/safe-html.pipe';
import {SafeStylePipe} from '@shared/pipes/safe-style.pipe';
import {SafeUrlPipe} from '@shared/pipes/safe-url.pipe';
import {PlainTextPipe} from '@shared/pipes/plain-text.pipe';
import {ImageCarouselComponent} from '@shared/components/image-carousel/image-carousel.component';
import {ImagePreviewModalComponent} from '@shared/components/image-preview-modal/image-preview-modal.component';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from '@angular/material/dialog';
import {UploadBuildModalComponent} from '@shared/components/upload-build-modal/upload-build-modal.component';
import {UploadBuildHistoryModalComponent} from '@shared/components/upload-build-history-modal/upload-build-history-modal.component';


@NgModule({
  declarations: [
    SafeHtmlPipe,
    SafeStylePipe,
    SafeUrlPipe,
    PlainTextPipe,
    TagsInputComponent,
    ImageCarouselComponent,
    ImagePreviewModalComponent,
    UploadBuildModalComponent,
    UploadBuildHistoryModalComponent,
  ],
  exports: [
    SafeHtmlPipe,
    SafeStylePipe,
    SafeUrlPipe,
    PlainTextPipe,
    TagsInputComponent,
    ImageCarouselComponent,
    ImagePreviewModalComponent,
    UploadBuildModalComponent,
    UploadBuildHistoryModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
  ],
  entryComponents: [
    ImagePreviewModalComponent,
    UploadBuildModalComponent,
  ],
  providers: [
    ApiService,
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true}}
  ],
})
export class SharedModule {
}
