import {Pipe, PipeTransform} from '@angular/core';
import {toPlainText} from '@shared/helpers/common';

@Pipe({
  name: 'plainText'
})
export class PlainTextPipe implements PipeTransform {

  constructor() {
  }

  transform(html): string {
    return toPlainText(html);
  }
}
