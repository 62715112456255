import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-image-preview-modal',
  templateUrl: './image-preview-modal.component.html'
})
export class ImagePreviewModalComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ImagePreviewModalComponent>,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  close() {
    this.dialogRef.close();
  }

  onNextImage(offset: number) {
    if (this.data.images && this.data.images.length > 1) {
      let index = this.data.images.indexOf(this.data.image);
      index = (index + offset + this.data.images.length) % this.data.images.length;
      this.data.image = this.data.images[index];
    }
  }
}
