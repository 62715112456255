import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FullLayoutComponent} from './layout/full-layout/full-layout.component';
import {AuthGuard} from '@shared/helpers/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    component: FullLayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('@modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'features',
        loadChildren: () => import('@modules/feature/feature.module').then(m => m.FeatureModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'test-suites',
        loadChildren: () => import('@modules/test-suites/test-suites.module').then(m => m.TestSuitesModule),
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'loginViaToken',
    loadChildren: () => import('@modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    loadChildren: () => import('@modules/public/public.module').then(m => m.PublicModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
