import {formatNumber} from '@angular/common';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';

export const validateAllFormFields = (formGroup: FormGroup) => {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({onlySelf: true});
    } else if (control instanceof FormGroup) {
      validateAllFormFields(control);
    }
  });
};

export const emailValidator = (): ValidatorFn => {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const isValid = !control.value || /^(([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])+)?$/.test(control.value);
    return isValid ? null : {pattern: true};
  };
};

export const urlValidator = () => {
  return Validators.pattern(/^(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/);
};

export const duplicateObject = (obj: any): any => {
  if (typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => duplicateObject(item));
  }

  const copy = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      copy[key] = duplicateObject(obj[key]);
    }
  }
  return copy;
};

export const formatFileSize = (size: number): string => {
  const units = ['', 'KB', 'MB', 'GB', 'TB'];
  let i = 0;
  do {
    size /= 1024;
    i ++;
  } while (size >= 1024 && i < units.length - 1);

  return `${formatNumber(size, 'en', '0.0-2')}${units[i]}`;
};

export const findParentElement = (el: Element, search: string | ((el: Element) => boolean)) => {
  let compareFn = search;
  if (typeof compareFn === 'string') {
    compareFn = (el: Element) => {
      if (typeof el.className !== 'string') {
        return false;
      }
      return el.className.includes(search as string);
    };
  }

  while (el) {
    if (compareFn(el)) {
      return el;
    }
    el = el.parentElement;
  }
  return null;
};

export const toPlainText = (html: string): string => {
  if (!html)
    return '';

  const el = document.createElement('div');
  el.innerHTML = html;
  const text = el.textContent;
  el.remove();
  return text;
};
