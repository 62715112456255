import moment from 'moment';

export function formatTag(tag: string): string {
  return `@${tag.replace(/ /g, '-')}`;
}

export function padString(str: string, len: number): string {
  if (str.length >= len)
    return str;

  const prefixLen = Math.floor((len - str.length) / 2);

  return str.padStart(prefixLen + str.length).padEnd(len);
}

export function formatTableAsText(data: string[][]): string {
  const len = data[0].map(() => 0);
  for (const row of data) {
    row.forEach((cell, colId) => {
      len[colId] = Math.max(len[colId], cell.length);
    });
  }

  let str = '';
  for (const row of data) {
    str += '|';
    row.forEach((cell, colId) => {
      str += ` ${padString(cell, len[colId])} |`
    });
    str += '\n';
  }

  return str;
}

export function isEqual(value1, value2): boolean {
  if (typeof value1 === 'object' || typeof value2 === 'object') {
    if (typeof value1 !== 'object' || typeof value2 !== 'object')
      return false;

    if (Object.keys(value1).length !== Object.keys(value2).length)
      return false;

    for (const key in value1) {
      if (value1[key] != value2[key])
        return false;
    }
    return true;
  }

  return value1 == value2;
}

export function duplicateObject(obj): any {
  if (typeof obj !== 'object')
    return obj;

  if (Array.isArray(obj)) {
    const arrayCopy = [];
    for (const value of obj)
      arrayCopy.push(duplicateObject(value));
    return arrayCopy;
  }

  const copy = {};
  for (const key in obj)
    copy[key] = duplicateObject(obj[key]);
  return copy;
}

export function getDuration(startTime, endTime): string {
  let duration = moment(endTime).diff(startTime, 's');
  const seconds = duration % 60;
  duration = Math.floor(duration / 60);
  if (!duration)
    return `${seconds}s`;
  const minutes = duration % 60;
  duration = Math.floor(duration / 60);
  if (!duration)
    return `${minutes}m ${seconds}s`;
  const hours = duration % 24;
  duration = Math.floor(duration / 24);
  return `${duration ? `${duration}d ` : ''}${hours}h ${minutes}m ${seconds}s`;
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}
